import React, { useContext } from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import TagManager from "react-gtm-module";

import Navbar from "./components/Navbar";
import { RegionContext } from "./contexts/region.context";

import Footer from "./components/Footer";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Region from "./pages/Region";

function usePageViews() {
  const location = useLocation();
  React.useEffect(() => {
    const dataLayerArgs = {
      dataLayer: {
        page: location.pathname + location.search,
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(dataLayerArgs);
  }, [location]);
}

function App() {
  const {
    state: { region },
  } = useContext(RegionContext);
  const localStorageRegion = JSON.parse(localStorage.getItem("spoilerRegion"));
  usePageViews();
  const hasSelectedRegion = localStorageRegion || region.id;
  const allowedRegions = ["saopaulo", "portoalegre"];
  const hasAllowedRegion = allowedRegions.some(
    (region) => region === localStorageRegion?.slug
  );

  return hasSelectedRegion && hasAllowedRegion ? (
    <>
      <Navbar />
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/sobre" component={About} />
        <Route path="/contato" component={Contact} />
        <Redirect from="/" to="/home" />
      </Switch>
      <Footer />
    </>
  ) : (
    <Region />
  );
}
function AppRouter() {
  return (
    <Router basename="/">
      <App />
    </Router>
  );
}

export default AppRouter;
