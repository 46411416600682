import React from "react";

function Instagram() {
  return (
    <div className="Instagram">
      <h2>
        <a
          href="https://www.instagram.com/barspoiler/?hl=pt-br"
          target="_blank"
          rel="noopener noreferrer"
        >
          @barspoiler
        </a>
      </h2>
    </div>
  );
}

export default Instagram;
