import React, { useEffect, useContext } from "react";
import { RegionContext } from "../../contexts/region.context";

function loadJSAsync(url) {
  let script = document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  script.setAttribute("data-loadtrk", true);
  script.setAttribute("onload", "this.loadtrk=true");
  var body = document.getElementsByTagName("body")[0];
  body.appendChild(script);
}
function TripAdvisor() {
  const {
    state: {
      region: { tripAdvisorId },
    },
  } = useContext(RegionContext);
  const urls = {
    write: `https://www.jscache.com/wejs?wtype=selfserveprop&uniq=13&locationId=${tripAdvisorId}&lang=pt&rating=true&nreviews=4&writereviewlink=true&popIdx=true&iswide=true&border=true&display_version=2`,
    read: `https://www.jscache.com/wejs?wtype=cdswritereviewlg&uniq=100&locationId=${tripAdvisorId}&lang=pt&lang=pt&display_version=2`,
  };

  useEffect(() => {
    loadJSAsync(urls.write);
    loadJSAsync(urls.read);
  }, [tripAdvisorId, urls.write, urls.read]);

  return tripAdvisorId ? (
    <div className="TripAdvisor container d-flex flex-column">
      <h2>Avalie sua visita</h2>
      <div
        id="TripAdvisorContainer"
        className="row flex-wrap justify-content-around align-items-center"
      >
        <div id="TA_cdswritereviewlg100" className="TA_cdswritereviewlg my-2">
          <span className="TripAdvisorError">
            Não conseguimos carregar as avaliações do TripAdvisor. <br />{" "}
            Recarregue a página para tentar novamente.
          </span>
          <ul id="ZekVHhdZoWy" className="TA_links JkKWggd">
            <li id="c61bB6Dqm47N" className="6CuNUrZMjwyf"></li>
          </ul>
        </div>
        <div id="TA_selfserveprop13" className="TA_selfserveprop my-2 px-4">
          <ul id="EUcfbAlsjT" className="TA_links wQaXK8q">
            <li id="FzZ0f393hyuP" className="8KZBBEycO"></li>
          </ul>
        </div>
      </div>
    </div>
  ) : null;
}

export default TripAdvisor;
