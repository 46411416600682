const sliderAdapter = (content = []) =>
  content.map(
    ({
      title: { rendered: title = "" },
      excerpt: { rendered: subtitle = "" },
      acf: { video = "", event_url = "" },
      type,
      _embedded = {},
    }) => {
      switch (type) {
        case "events":
          let [{ source_url: media }] = _embedded["wp:featuredmedia"] || [];
          return {
            title,
            subtitle,
            event_url,
            media,
            type,
          };

        default:
          return {
            title,
            subtitle,
            video,
            type,
          };
      }
    }
  );
export default sliderAdapter;
