import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import { RegionContext } from "../../contexts/region.context";
import HeaderBanner from "../../components/HeaderBanner";

import TripAdvisor from "../../components/TripAdvisor";
import Slider from "./Slider";

import booking from "../../assets/img/Booking.jpeg";

function Home() {
  const {
    state: {
      region: {
        slug: regionSlug,
        leadsFood: { url },
      },
    },
  } = useContext(RegionContext);
  const location = useLocation();
  const bannerPath = `${location.pathname}-${regionSlug}`;
  return regionSlug ? (
    <div className="HomePage">
      <HeaderBanner path={bannerPath} />
      <div className="container d-flex flex-wrap mt-1">
        <div className="col-12 col-md-6 mb-4">
          <a href={`${url}/reservation`} rel="noreferrer" className="Booking">
            <div className="subtitle">
              <b>Reservas</b>
            </div>
            <img src={booking} className="img-fluid BookingImage" alt="" />
          </a>
        </div>
        <div className="col-12 col-md-6">
          <Slider type="videos" />
        </div>
      </div>

      <TripAdvisor />
    </div>
  ) : null;
}

export default Home;
