import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import iconTwitter from "../../assets/img/icon_twitter.png";
import iconInstagram from "../../assets/img/icon_instagram.png";
import iconFacebook from "../../assets/img/icon_facebook.png";
import iconLocation from "../../assets/img/icon_contact_pin.png";
import iconPhone from "../../assets/img/icon_contact_phone.png";
import iconWhatsapp from "../../assets/img/icon_contact_whatsapp.png";
import iconClock from "../../assets/img/icon_contact_clock.png";
import iconMail from "../../assets/img/icon_contact_mail.png";
import { handleACFLineBreak } from "../../methods/handleACFLineBreaks";
import { RegionContext } from "../../contexts/region.context";

const ContactInfo = React.memo(function ContactInfo(props) {
  const [contactInfos, setContactInfos] = useState([]);
  const {
    state: {
      region: { id: regionId },
    },
  } = useContext(RegionContext);
  function fetchInfo(region, callback) {
    if (!region) return;
    let options = {
      params: {
        per_page: 1,
        _embed: "",
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/wp/v2/infos_contato?region=${region}`,
        options,
      )
      .then(({ data }) => {
        callback(data);
      });
  }

  useEffect(() => {
    if (!regionId) return;
    fetchInfo(regionId, setContactInfos);
    return () => {};
  }, [regionId]);

  return (
    <div className="container mt-2">
      {contactInfos.map((contactInfo) => {
        const {
          address,
          opening_hours,
          phone,
          whatsapp,
          email,
          facebook,
          instagram,
          twitter,
          google_maps_embed,
          google_maps_link,
        } = contactInfo.acf || {};
        return (
          <div className="row mb-5 justify-content-center" key={address}>
            <div className="col-12 col-md-6">
              <iframe
                src={`https://www.google.com/maps/embed?${google_maps_embed}`}
                width="600"
                height="450"
                allowFullScreen
                loading="lazy"
                title={address}
              />
            </div>
            <div className="col-12 col-md-6">
              <ul className="ContactPage__List">
                <li className="ContactPage__List-Item">
                  <img src={iconLocation} alt="" className="icon" />
                  <a
                    href={google_maps_link}
                    className="ContactPage__List-Text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: handleACFLineBreak(address),
                      }}
                    />
                  </a>
                </li>
                <li className="ContactPage__List-Item">
                  <img src={iconClock} alt="" className="icon" />
                  <div className="ContactPage__List-Text">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: handleACFLineBreak(opening_hours),
                      }}
                    />
                  </div>
                </li>
                <li className="ContactPage__List-Item">
                  <img src={iconPhone} alt="" className="icon" />
                  <a
                    href={`tel:${phone}`}
                    className="ContactPage__List-Text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {phone}
                  </a>
                </li>
                <li className="ContactPage__List-Item">
                  <img src={iconWhatsapp} alt="" className="icon" />
                  <a
                    href={`https://wa.me/55${whatsapp.replace(" ", "")}`}
                    className="ContactPage__List-Text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {whatsapp}
                  </a>
                </li>
                <li className="ContactPage__List-Item">
                  <img src={iconMail} alt="" className="icon" />
                  <a
                    href={`mailto:${email}`}
                    className="ContactPage__List-Text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email}
                  </a>
                </li>
                <li className="ContactPage__List-Item">
                  <img src={iconFacebook} alt="" className="icon" />
                  <a
                    href={`https://www.facebook.com/${facebook}/`}
                    className="ContactPage__List-Text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    /{facebook}
                  </a>
                </li>
                <li className="ContactPage__List-Item">
                  <img src={iconInstagram} alt="" className="icon" />
                  <a
                    href={`https://www.instagram.com/${instagram}`}
                    className="ContactPage__List-Text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @{instagram}
                  </a>
                </li>
                <li className="ContactPage__List-Item">
                  <img src={iconTwitter} alt="" className="icon" />
                  <a
                    href={`https://www.twitter.com/${twitter}`}
                    className="ContactPage__List-Text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @{twitter}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default ContactInfo;
