import React, { useEffect, useContext } from "react";
import DesktopNav from "./desktop";
import MobileNav from "./mobile";
import { Logo } from "./logo";
import { RegionContext } from "../../contexts/region.context";
import { withRouter } from "react-router-dom";

export default withRouter(({ history }) => {
  const {
    state: {
      region: {
        id: regionId,
        leadsFood: { url, hash },
      },
    },
    dispatch,
  } = useContext(RegionContext);

  useEffect(() => {
    const isOnRegionSelecion = history.location.pathname === "/onde-voce-esta";
    const localStorageRegion = JSON.parse(
      localStorage.getItem("spoilerRegion")
    );
    if (!localStorageRegion) {
      return history.push("/onde-voce-esta");
    }
    const hasOldRegionFormat =
      !localStorageRegion.hasOwnProperty("phonePrefix");

    if (!isOnRegionSelecion && hasOldRegionFormat) {
      history.push("/onde-voce-esta");
    } else if (regionId === 0 && !isOnRegionSelecion) {
      dispatch({ type: "SET_REGION", payload: localStorageRegion });
    }
  }, [dispatch, history, regionId]);

  return (
    <header className="Navbar">
      <div className="container">
        {regionId ? (
          <>
            <DesktopNav leadsFoodUrl={url} leadsFoodEventHash={hash} />
            <MobileNav leadsFoodUrl={url} leadsFoodEventHash={hash} />
          </>
        ) : (
          <nav className="">
            <Logo />
          </nav>
        )}
      </div>
    </header>
  );
});
