import React from "react";

function Loader({ loading }) {
  return loading ? (
    <div className="container">
      <div className="Loader">
        <div />
        <div />
        <div />
      </div>
    </div>
  ) : null;
}

export default Loader;
