import React, { createContext, useReducer } from "react";

const initialState = {
  region: {
    name: "",
    slug: "",
    tripadvisorid: "",
    phoneprefix: "",
    id: 0,
    leadsFood: {
      url: "",
      hash: "",
    },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_REGION":
      const isSp = action.payload.id === 34;
      const hashSp = "9cded8cb-d707-4d5b-9158-6c85829f0bb4";
      const hashPoa = "9cdea2fe-74ee-4bb1-857c-a2dfc7f3c294";
      const hash = isSp ? hashSp : hashPoa;
      const storeSuffix = isSp ? "sp" : "bar";
      const mainUrl = `https://spoiler${storeSuffix}.leadsfood.app`;
      return {
        ...state,
        region: { ...action.payload, leadsFood: { url: mainUrl, hash: hash } },
      };
    case "CLEAN_REGION":
      localStorage.removeItem("spoilerRegion");
      return { ...state, ...initialState };
    default:
      return { ...state };
  }
};

export const RegionContext = createContext({});

export function RegionContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RegionContext.Provider value={{ state, dispatch }}>
      {props.children}
    </RegionContext.Provider>
  );
}
