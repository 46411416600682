import React, { Component } from "react";
import Slider from "react-slick";

class DefaultSlider extends Component {
  render() {
    var settings = {
      arrows: this.props.arrows,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <section className={`DefaultSlider ${this.props.className}`}>
        <div className="container">
          <div className="row">
            <Slider
              {...settings}
              className={`${this.props.arrows ? "has-arrows " : ""}${this.props.half ? "half" : ""}`}
            >
              {this.props.content.map((item, index) => {
                switch (item.type) {
                  case "video":
                    return (
                      <div
                        className="embed-responsive embed-responsive-1by1"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item.video }}
                      />
                    );
                  default:
                    return (
                      <a
                        href={item.event_url}
                        className="embed-responsive embed-responsive-1by1"
                        key={index}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src={item.media}
                          alt=""
                          className="embed-responsive-item"
                        />
                        <div className="subtitle">
                          {item.title && (
                            <span
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          )}
                          {item.subtitle && (
                            <b
                              dangerouslySetInnerHTML={{
                                __html: item.subtitle,
                              }}
                            />
                          )}
                        </div>
                      </a>
                    );
                }
              })}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default DefaultSlider;
