import React from "react";
import { NavLink } from "react-router-dom";
import { LogoRegion as Logo } from "./logo";

function DesktopNavbar({ leadsFoodUrl, leadsFoodEventHash }) {
  function Link({ label, slug }) {
    return (
      <NavLink
        activeClassName="Navbar__Item--active"
        to={`/${slug}`}
        className="Navbar__Item"
      >
        {label}
      </NavLink>
    );
  }

  return (
    <nav className="d-none d-lg-flex">
      <Link label="Home" slug="home" />
      <Link label="Sobre" slug="sobre" />
      <a href={`${leadsFoodUrl}/reservation`} rel="noreferrer">
        Reservas
      </a>
      <NavLink to="/home" className="Navbar__Item">
        <Logo />
      </NavLink>
      <a href={`${leadsFoodUrl}/menu`} rel="noreferrer">
        Cardápio
      </a>
      <a href={`${leadsFoodUrl}/menu/${leadsFoodEventHash}`} rel="noreferrer">
        Agenda
      </a>
      <Link label="Contato" slug="contato" />
    </nav>
  );
}

export default DesktopNavbar;
