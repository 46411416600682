import React, { useEffect, useState } from "react";
import { fetchPageContent } from "../../methods/fetch-content";

import HeaderBanner from "../../components/HeaderBanner";
import Instagram from "../../components/Instagram";
import Loader from "../../components/Loader";

function About(props) {
  const [content, setContent] = useState({});

  useEffect(() => {
    fetchPageContent(props.match.path).then(
      ({ banners, content, sucesso, erro }) => {
        setContent({
          mainText: content,
          banners,
          messages: { sucesso, erro },
        });
      }
    );
  }, [props.match.path]);

  return content.mainText ? (
    <div className="About">
      <HeaderBanner content={content.banners} />
      <div className="container mt-2">
        <div dangerouslySetInnerHTML={{ __html: content.mainText }} />
        <Instagram />
      </div>
    </div>
  ) : (
    <Loader loading />
  );
}

export default About;
