import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { RegionContext } from "../../contexts/region.context";

import axios from "axios";
import logo from "../../assets/img/logo_graphic.png";
import instagram from "../../assets/img/icon_instagram.png";
import facebook from "../../assets/img/icon_facebook.png";

function fetchText(region, callback) {
  if (!region) return;
  callback("");
  let options = {
    params: {
      per_page: 1,
      _embed: "",
    },
  };
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/wp/v2/infos_funcionamento?region=${region}`,
      options,
    )
    .then(({ data }) => {
      if (!data.length) return;
      callback(data[0].content.rendered);
    });
}

function Footer() {
  const {
    state: { region },
    dispatch,
  } = useContext(RegionContext);
  const [text, setText] = useState("");
  function cleanRegion() {
    dispatch({ type: "CLEAN_REGION" });
  }

  useEffect(() => {
    fetchText(region.id, setText);
  }, [region]);

  return region.id ? (
    <div className="container">
      <hr />
      {text ? (
        <footer className="Footer">
          <div className="container">
            <nav className="col-12">
              <div className="Footer__Social">
                <a
                  href="https://www.instagram.com/barspoiler/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={instagram}
                    className="Footer__Social-icon"
                    alt="logo"
                  />
                </a>
                <a
                  href="https://www.facebook.com/spoilerbar"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2"
                >
                  <img
                    src={facebook}
                    className="Footer__Social-icon"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="Footer__Disclaimer">
                <div dangerouslySetInnerHTML={{ __html: text }} />
                <span onClick={cleanRegion} className="Footer__Region">
                  Ver outra cidade
                </span>
              </div>

              <div className="Footer__Owner">
                <NavLink to={"/Home"} className="Footer__Owner-item">
                  <img src={logo} className="logo" alt="logo" />
                </NavLink>
                <span>Spoiler @ 2021</span>
              </div>
            </nav>
          </div>
        </footer>
      ) : null}
    </div>
  ) : null;
}

export default React.memo(Footer);
