import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { LogoRegion as Logo } from "./logo";
const localStorageRegion = JSON.parse(localStorage.getItem("spoilerRegion"));

function NavbarMobile({ leadsFoodUrl, leadsFoodEventHash }) {
  const [dropdownMenu, setDropdownMenu] = useState(false);

  function toggleMenu() {
    setDropdownMenu(!dropdownMenu);
  }

  function Link({ label, slug, href }) {
    return slug ? (
      <NavLink
        activeClassName="Navbar__Item--active"
        to={`/${slug}`}
        className="Navbar__Item cat"
        onClick={toggleMenu}
      >
        {label}
      </NavLink>
    ) : (
      <a href={href} rel="noreferrer" className="Navbar__Item dpokpodk">
        {label}
      </a>
    );
  }

  return (
    <nav className="d-flex d-lg-none">
      <div className="Navbar__Header">
        <NavLink
          to={`/${localStorageRegion?.slug}/home`}
          className="Navbar__Logo"
        >
          <Logo />
        </NavLink>
        <div className="Navbar__Toggle" onClick={toggleMenu} />
      </div>
      <div className={`Navbar__Items ${dropdownMenu ? "open" : " closed"}`}>
        <Link label="Home" slug="home" />
        <Link label="Sobre" slug="sobre" />
        <Link label="Reservas" href={`${leadsFoodUrl}/reservation`} />
        <Link label="Cardápio" href={`${leadsFoodUrl}/menu`} />
        <Link
          label="Agenda"
          href={`${leadsFoodUrl}/menu/${leadsFoodEventHash}`}
        />
        <Link label="Contato" slug="contato" />
      </div>
    </nav>
  );
}

export default NavbarMobile;
