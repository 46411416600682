import axios from "axios";

export function fetchPageContent(path, regionName) {
  const normalizedPath = `${path.replace(/\//g, "")}`;
  const regionalSuffix = regionName ? `-${regionName.toLowerCase()}` : "";
  const pageSlug = `${normalizedPath}${regionalSuffix}`;

  return axios
    .get(`${process.env.REACT_APP_API_URL}/wp/v2/pages`, {
      params: {
        slug: pageSlug,
      },
    })
    .then(
      ({
        data: [
          {
            content: { rendered: content } = { rendered: "" },
            acf: {
              banners,
              sucesso,
              erro,
              booking_max_capacity: maxCapacity = 10,
            },
          } = {
            acf: { banners: [], sucesso: {}, erro: {} },
          },
        ],
      }) => ({ banners, content, sucesso, erro, maxCapacity }),
    );
}
