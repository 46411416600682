import React, { useState, useEffect } from "react";

import HeaderBanner from "../../components/HeaderBanner";
import Loader from "../../components/Loader";

import { fetchPageContent } from "../../methods/fetch-content";

import heisenberg from "../../assets/img/heisenberg.png";
import ContactInfo from "./ContactInfo";

function Contact(props) {
  const [content, setContent] = useState({});
  useEffect(() => {
    fetchPageContent(props.match.path).then(
      ({ banners, content, sucesso, erro }) => {
        setContent({
          mainText: content,
          banners,
          messages: { sucesso, erro },
        });
      }
    );
    return () => {};
  }, [props.match.path]);

  const { banners, mainText } = content || {};

  return mainText ? (
    <div className="ContactPage">
      <HeaderBanner content={banners} />
      <div className="container mt-2">
        <div className="row mb-5 justify-content-center">
          <ContactInfo />
          <div className="col-12 mt-5 d-flex justify-content-center">
            <div className="row">
              <img
                src={heisenberg}
                alt="drink Heisenberg"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader loading />
  );
}
export default Contact;
