import React, { useContext } from "react";
import logo from "../../assets/img/logo.png";
import { RegionContext } from "../../contexts/region.context";

export function Logo() {
  return (
    <div className="Navbar__LogoWrapper">
      <img src={logo} className="logo" alt="logo" />
    </div>
  );
}
export function LogoRegion() {
  const {
    state: { region },
  } = useContext(RegionContext);

  return (
    <div className="Navbar__LogoWrapper">
      <img src={logo} className="logo" alt="logo" />
      <span className="Navbar__LogoRegion">{region.name}</span>
    </div>
  );
}
