import React, { Component } from "react";
import AppRouter from "./AppRouter";
import { RegionContextProvider } from "./contexts/region.context.js";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="Spoiler">
        <RegionContextProvider>
          <AppRouter />
        </RegionContextProvider>
      </div>
    );
  }
}

export default App;
