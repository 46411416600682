import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import HeaderBannerAdapter from "../../adapters/HeaderBannerAdapter";
import { fetchPageContent } from "../../methods/fetch-content";
import Loader from "../../components/Loader";

function fetchBanners(path, callback) {
  fetchPageContent(path).then(({ banners }) => {
    callback(banners);
  });
}

function HeaderBanner({ path, content, hideLoader = false }) {
  const [banners, setBanners] = useState([]);
  const sliderRef = useRef(null);
  useEffect(() => {
    if (!content) {
      fetchBanners(path, setBanners);
    }
    return () => {
      window.scrollTo(0, 0);
    };
  }, [content, path]);

  const bannersContent = content || banners;
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    adaptiveHeight: true,
    slidesToScroll: 1,
  };

  return bannersContent.length ? (
    <section className="HeaderBanner">
      <div className="container">
        <div className="row">
          <Slider {...settings} ref={sliderRef}>
            {HeaderBannerAdapter(bannersContent).map((item, index) => {
              return (
                <div key={index} onClick={() => sliderRef.current.slickNext()}>
                  <img src={item.media} alt="" className="img-fluid" />
                  <div className="subtitle">
                    {item.title}
                    <b>{item.subtitle}</b>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  ) : (
    <Loader loading={!hideLoader} />
  );
}

export default React.memo(HeaderBanner);
