import React, { useState, useEffect } from "react";
import axios from "axios";

import DefaultSlider from "../../components/DefaultSlider";
import DefaultSliderAdapter from "../../adapters/DefaultSliderAdapter";

function fetchSlider(type, callback) {
  axios
    .get(`${process.env.REACT_APP_API_URL}/wp/v2/${type}?_embed`)
    .then(({ data: events }) => callback(events));
}

function Slides({ type }) {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    fetchSlider(type, setSlides);
    return () => {};
  }, [type]);

  return slides.length ? (
    <DefaultSlider
      className="Calendar"
      content={DefaultSliderAdapter(slides)}
      hasMedia={false}
      arrows={true}
      half={true}
    />
  ) : null;
}

export default React.memo(Slides);
